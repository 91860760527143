import React from 'react';
import logo from '../images/logo.svg';
import apple from '../images/apple.svg';
import google from '../images/google_play.svg';
import phone from '../images/phone.png';

function Header({page}) {
    return <div className="header gp">
        <div className="left">
            <img src={logo} alt="logo" />
            <h1><span>{page.slogan}</span><br/>{page.title}</h1>
            <div className="download">
                <a href={page.links && page.links.appStore} rel="noreferrer noopener" target="_blank">
                    <img src={apple} alt="apple store" />
                </a>
                <a href={page.links && page.links.googlePlay} rel="noreferrer noopener" target="_blank">
                    <img src={google} alt="google play" />
                </a>
            </div>
        </div>
        <div className="right">
            <img src={phone} alt="phone" />
        </div>
    </div>
}
export default Header;
