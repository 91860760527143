import React, { useState, useEffect } from "react";
import Header from "./components/header";
import Features from "./components/features";
import Like from "./components/like";
import Download from "./components/download";
import Footer from "./components/footer";

import ReactGA from 'react-ga';
ReactGA.initialize('UA-42054416-6');

const sanityClient = require('@sanity/client');

const client = sanityClient({
    projectId: 'w353uoe9',
    dataset: 'production',
    // token: 'sanity-auth-token', // or leave blank to be anonymous user
    useCdn: true,
    ignoreBrowserWarning: true
});

const query = '*[_type == "homepage" && !(_id match "drafts")]{slogan, title, "features": features[]{title, description, "image": image.asset->url,}, links,text, "social": social[]->{name, link, "image": image.asset->url,}}';

const App = () => {
    const  [page,setPage ]= useState([]);


    useEffect(() => {
        async function fetchData() {
            const page = await client.fetch(query);
            setPage(page[0]);
        }
        fetchData();
    }, []);

    return (
        <div>
            <Header page={page}/>
            <Features page={page}/>
            <Like page={page}/>
            <Download page={page}/>
            <Footer page={page}/>
        </div>
    );
};

export default App;
