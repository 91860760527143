import React from 'react';
import logo from '../images/logo.svg';
function Footer({page}) {
    return <footer className="gp">
        <img src={logo} alt="logo" />
        <h3>{page.text && page.text.followText}</h3>
        <div className="social">
            {page.social && page.social.map((item, i) =>
                <a key={i} href={item.link} rel="noreferrer noopener" target="_blank">
                    <img width="31" height="31" src={item.image} alt={item.name} />
                </a>
            )}
        </div>
        <div className="legal">
            <a href="#">{page.text && page.text.copyright}</a>
            <a href={page.links && page.links.privacy } rel="noreferrer noopener" target="_blank">Privacy Policy</a>
            <a href={page.links && page.links.terms } rel="noreferrer noopener" target="_blank">Terms and Conditions</a>
        </div>
    </footer>
}
export default Footer;
