import React from 'react';
import hand from "../images/hand.png";
import like from "../images/like.svg";
function Like({page}) {
    return <div className="like">
        <img className="hand" src={hand} alt="hand" />
        <div className="count">
            <a href="https://www.facebook.com/yatzyonlinefanpage" rel="noopener noreferrer" target="_blank">
                <img src={like} alt="like" />
            </a>
            <span>445</span>
        </div>
        <h3 className="text">{page.text && page.text.likeText}</h3>
    </div>
}
export default Like;
