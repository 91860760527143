import React from 'react';

function Features({page}) {
    return <div className="stats gp">
        {page.features && page.features.map((item, i)=>
            <div className="stat" key={i}>
                <img src={item.image} alt="reply" />
                <h3>{item.title}</h3>
                <p>{item.description}</p>
            </div>
        )}
    </div>
}
export default Features;
