import React from 'react';
import apple from '../images/apple.svg';
import google from '../images/google_play.svg';

function Download({page}) {
    return <div className="download gp">
        <h2>{page.text && page.text.downloadText}</h2>
        <div>
            <a href={page.links && page.links.appStore} rel="noreferrer noopener" target="_blank">
                <img src={apple} alt="apple store" />
            </a>
            <a href={page.links && page.links.googlePlay} rel="noreferrer noopener" target="_blank">
                <img src={google} alt="google play" />
            </a>
        </div>
    </div>
}
export default Download;
